import * as React from 'react';
import {PureComponent} from 'react';

const pinStyle = {
  enableBackground: "new 0 0 512 512"
};

export default class Pin extends PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style={pinStyle} width="32px" height="32px" ><g><path style={{fill: "#FF6F00"}} d="M433.531,177.531c0,40.043-28.086,106.04-83.477,196.141  c-40.503,65.887-81.586,121.479-81.996,122.039L256,512l-12.057-16.289c-0.41-0.56-41.493-56.152-81.996-122.039  c-55.391-90.101-83.477-156.098-83.477-196.141C78.469,79.645,158.105,0,256,0S433.531,79.645,433.531,177.531z" data-original="#FF1A4B"  data-old_color="#FF1A4B"/><path style={{fill: "#CD5A00"}} d="M357.517,174.779c0,55.982-45.536,101.527-101.518,101.527s-101.517-45.546-101.517-101.527  c0-55.972,45.536-101.518,101.517-101.518S357.517,118.807,357.517,174.779z" data-original="#FFDBA9" className="active-path" data-old_color="#FFDBA9"/><path style={{fill: "#FF6F00"}} d="M433.531,177.531c0,40.043-28.086,106.04-83.477,196.141  c-40.503,65.887-81.586,121.479-81.996,122.039L256,512V0C353.895,0,433.531,79.645,433.531,177.531z" data-original="#D5243E"  data-old_color="#D5243E"/><path style={{fill: "#CD5A00"}} d="M357.517,174.779c0,55.982-45.536,101.527-101.518,101.527V73.261  C311.982,73.261,357.517,118.807,357.517,174.779z" data-original="#FFC473"  data-old_color="#FFC473"/></g> </svg>
    );
  }
}
