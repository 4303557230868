import * as React from 'react';
import {Component} from 'react';
import MapGL, {Marker, FlyToInterpolator, Source, Layer} from 'react-map-gl';
import {clusterLayer, clusterCountLayer, unclusteredPointLayer} from './layers';
import Pin from './pin';
import GPS from './gps';
import {
  Button, ButtonGroup,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Nav, NavItem, NavLink, TabContent, TabPane,
  Toast, ToastBody, Badge,
  Popover, PopoverHeader, PopoverBody, UncontrolledPopover
} from "reactstrap";
import classnames from "classnames";
import firebase from './firebase';
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import ProductItem from './productItem';
import Geocoder from "react-map-gl-geocoder";
import cities from "./cities.json";
import sampleShops from "./v2.shops.json";
// import sampleClicks from "./v2.clicks.json";
import sampleCoors from "./coors.json";
import Vin_DSST from "./db_import/output/Vin_DSST.json";
import Vin_DSCH from "./db_import/output/Vin_DSCH.json";
import Select from "react-select"
import chroma from "chroma-js";
import * as turf from "@turf/turf";
import "@turf/distance";
import Verify from './verify';
import update from 'react-addons-update';
import { render } from 'react-dom';
// import ImportDB from './importDB';

const editors = JSON.parse(process.env.REACT_APP_EDITORS)


const ImportedDataArray = {
  Vin_DSST: Vin_DSST,
  Vin_DSCH: Vin_DSCH
}

const ImportedData = Vin_DSST.concat(Vin_DSCH)

const emptyShop = {
  id: "",
  coors: "",
  name: "",
  city: "",
  address: "",
  phone: "",
  description: "",
  certification: false,
  verify: [],
  verifyCount: 0,
  owner: "",
  products: [
    {}, {}, {}, {}, {}
  ],
  created_at: "",
  updated_at: ""
}

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

export default class App extends Component {
  state = {
    viewport: {
      latitude: 10.781198294254992,
      longitude: 106.691258742002,
      zoom: 12,
      bearing: 0
    },
    interactionState: {},
    settings: {
      dragPan: true,
      dragRotate: true,
      scrollZoom: true,
      touchZoom: true,
      keyboard: true,
      doubleClickZoom: true,
      minZoom: 0,
      maxZoom: 20,
    },
    showModalViewShop: false,
    showModalNewShop: false,
    activeTabNewShop: "new-shop-info",
    shopIsOpen: null,
    shopIsOpenIndex: null,
    shop: emptyShop,
    allShops: [],
    viewShops: [],
    viewShopsGeoJson: {},
    myShops: [],
    currentUser: null,
    productTypes: ["Khẩu trang", "Gel rửa tay khô", "Kính bảo hộ", "Áo mưa kháng khuẩn", "Khác"],
    productTypeOptions: [
      { value: "Khẩu trang", label: "Khẩu trang", color: "#35C4A6" },
      { value: "Gel rửa tay khô", label: "Gel rửa tay khô", color: "#FF6F00" },
      { value: "Kính bảo hộ", label: "Kính bảo hộ", color: "#FDB32C" },
      { value: "Áo mưa kháng khuẩn", label: "Áo mưa kháng khuẩn", color: "#2683ff" },
      { value: "Khác", label: "Khác", color: "#adb5bd" },
    ],
    productUnits: ["Cái", "Hộp", "Chai", "Bộ", "Khác"],
    headerTabActive: 1,
    currentSelectedCity: "",
    shopCoorsEditingId: null,
    toastIsOpen: false,
    toastMessage: "",
    toastTimer: null,
    filterValues: [],
    isOpenSidebar: false,
    allowLocationStatus: null,
    isFindingLocation: false,
    popoverStepOne: false,
    popoverStepTwo: false,
    popoverStepThree: false,
    enableAddShopBtn: false,
    showPhoneNumber: false,
    loadingData: true,
    totalShops: 0
  };

  mapRef = React.createRef();

  constructor(props) {
    super(props);

    this.db = firebase.firestore();
    this.storage = firebase.storage().ref();
    this._handleInputChange = this._handleInputChange.bind(this);
    this.mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN
  }

  _onViewportChange = viewport => {
    this.setState({viewport});
  }

  _onInteractionStateChange = interactionState => {
    const { allShops, headerTabActive, popoverStepTwo, popoverStepThree } = this.state
    if (!interactionState.isDragging) {
      this.setState({
        viewShops: this.sortBycoors(allShops)
      });
    }

    if ((interactionState.isDragging || interactionState.isZooming) && !interactionState.inTransition) {
      if (headerTabActive === 2 && popoverStepTwo) {
        this.setState({
          popoverStepTwo: false
        });
      }
    }

    if (!interactionState.isDragging && !interactionState.isZooming) {
      if (headerTabActive === 2 && !popoverStepThree && !popoverStepTwo) {
        const popoverStepThreeEnable = this.state.myShops.length === 0 ? true : false
        this.setState({
          popoverStepOne: false,
          popoverStepTwo: false,
          popoverStepThree: popoverStepThreeEnable,
          enableAddShopBtn: true
        });
      }
    }

    if (
      (interactionState.isDragging || interactionState.isZooming) &&
      (headerTabActive === 2 && popoverStepThree)) {
      this.setState({
        popoverStepThree: false,
        enableAddShopBtn: false
      });
    }
  }

  _onClick = event => {
    const feature = event.features[0];

    if (feature === undefined) return;

    if (feature.layer.id === 'unclustered-point') {
      return this.onClickMarker(feature.properties)
    }

    if (feature.id === undefined) return;

    const clusterId = feature.properties.cluster_id;

    const mapboxSource = this.mapRef.current.getSource();
    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }
      this._onViewportChange({
        ...this.state.viewport,
        longitude: feature.geometry.coordinates[0],
        latitude: feature.geometry.coordinates[1],
        zoom,
        transitionDuration: 500
      });
    });
  };

  _onHover = event => {
    const shopIsHover = event.features;
    if (shopIsHover && shopIsHover.length) {
      // hoverInfo = {
      //   lngLat: event.lngLat,
      //   county: county.properties
      // };
      // countyName = county.properties.COUNTY;
      // let overlays = document.getElementsByClassName("overlays")
      // overlays[0].classList.add("hovering")
      console.log(shopIsHover);
    }
  };

  toggleModalShopViewShop() {
    this.setState({
      showModalViewShop: !this.state.showModalViewShop,
      showPhoneNumber: false
    })
  }

  removeShop(shop) {
    const self = this;
    const { allShops } = this.state

    self.setState({
      shopIsOpen: false
    });

    this.db.collection("v2.shops").doc(shop.id).delete().then(function() {
      self.setState({
        allShops: self.removeShopById(allShops, shop.id)
      })
      self.generateShopCoorsByState()
    });

    this.showToast("Xóa Shop thành công!")
  }

  toggleModalShopNewShop(currentShop = null) {
    const self = this

    this.setState({
      showModalViewShop: false,
      showPhoneNumber: false
    })

    if (currentShop !== null) {
      this.db.collection("v2.shops").doc(currentShop.id).get().then(function(doc) {
        const shop = {...doc.data()}

        self.setState({
          shop: shop,
          showModalNewShop: !self.state.showModalNewShop
        });
      });
    } else {
      self.setState({
        showModalNewShop: !self.state.showModalNewShop
      });

      if (this.state.shop.id !== "") {
        self.setState({
          shop: emptyShop
        });
      }
    }
  }

  _toggleTabNewShop(tab) {
    this.setState({
      activeTabNewShop: tab
    })
  }

  _handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = name === "certification" ? target.checked : target.value;

    let shopInfo = JSON.parse(JSON.stringify(this.state.shop))
    shopInfo[name] = value

    this.setState({
      shop: shopInfo
    });
  }

  _handleProductInputChange = (event, index) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    let shopInfo = JSON.parse(JSON.stringify(this.state.shop))

    shopInfo["products"] = this.state.shop.products.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {
          ...item,
          [name]: value
        }
      }
      return item;
    })

    this.setState({
      shop: shopInfo
    });
  }

  _handleProductInputImageChange(event, index) {
    const target = event.target;
    const file = target.files[0];

    let uploadTask = this.storage.child(this.state.currentUser.uid + "/" + file.lastModified + "-" + file.name).put(file);
    let self = this;

    uploadTask.on('state_changed', function(snapshot) {
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          // console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING:
          // console.log('Upload is running');
          break;
        default:
      }
    }, function(error) {
    }, function() {
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {

        let shopInfo = JSON.parse(JSON.stringify(self.state.shop))

        shopInfo["products"] = self.state.shop.products.map((item, itemIndex) => {
          if (itemIndex === index) {
            return {
              ...item,
              ["image"]: downloadURL
            }
          }
          return item;
        })

        self.setState({
          shop: shopInfo
        });
      });
    });
  }

  onSubmitForm = (event) => {
    event.preventDefault();
    const self = this;
    const shopInfo = this.state.shop;
    let allShops = this.state.allShops;

    if (shopInfo.owner === "") {
      shopInfo.owner = this.state.currentUser.uid
    }

    if (shopInfo.created_at === "") {
      shopInfo.created_at = new Date()
    }

    shopInfo.updated_at = new Date()

    const isNewItem = shopInfo.id ? false : true

    let message = isNewItem ? "Thêm mới thành công!": "Cập nhật thành công!"

    if (isNewItem) {
      shopInfo.coors = [this.state.viewport.latitude, this.state.viewport.longitude]

      Object.defineProperty(shopInfo, "id", {
        value: shopInfo.owner + "-" + Date.now().toString(),
        writable: true,
      });

      const shopCoors = this.convertToShopCoors(shopInfo)
      allShops.push(shopCoors);
    } else {
      const shop = allShops.find(x => x.id === shopInfo.id)
      const shopIndex = allShops.indexOf(shop)
      const shopCoors = this.convertToShopCoors(shopInfo)

      allShops = JSON.parse(JSON.stringify(allShops))
      allShops[shopIndex] = shopCoors

      self.setState({
        allShops
      })
    }

    // Add or update shopInfo
    this.db.collection("v2.shops").doc(shopInfo.id).set(shopInfo).then(function() {
      self.generateShopCoorsByState()
      self.setState({
        shop: emptyShop,
      })
    });

    this.toggleModalShopNewShop()
    this.showToast(message)
  }

  _signIn() {
    if (process.env.NODE_ENV === "development") {
      firebase.auth().signInAnonymously().then(function(result) {
        console.log(result);
      });
    } else {
      var provider = new firebase.auth.FacebookAuthProvider();
      firebase.auth().signInWithRedirect(provider);
    }
  }

  _signOut() {
    firebase.auth().signOut().then(function() {
      window.location.reload();
    }).catch(function(error) {
      window.location.reload();
    });
  }

  setupForDevEnv() {
    const self = this
    // firebase.firestore().disableNetwork()

    // Offline
    // this.db.collection("v2.shops").where("id", "==", "").get().then(function(querySnapshot) {
    //   querySnapshot.forEach((doc) => {
    //     console.log(doc.id);
    //     self.db.collection("v2.shops").doc(doc.id).update({
    //       id: doc.id
    //     })
    //   });
    // })

    // From shops table
    // this.generateShopCoorsByShops()
    // this.generateShopCoorsByShopsOffline()
    // From state
    // this.generateShopCoorsByState()

    // From database
    this.setShopsStateByCoors()
    // new ImportDB().run()

    // From json file
    // this.setShopsStateByCoorsOffline()

    // this.printShopNotByEditor()
    // this.findShopDuplicate()
  }

  setShopsStateByCoorsOffline() {
    const currentUser = this.state.currentUser
    let myShops = []
    if (currentUser !== null) {
      sampleCoors.forEach((shopInfo) => {
        if (shopInfo.owner === currentUser.uid) {
          myShops.push(shopInfo);
        }
      });
    }

    this.setState({
      allShops: sampleCoors,
      viewShops:sampleCoors,
      myShops
    });
  }

  setShopsStateByCoors() {
    let self = this
    self.setState({
      allShops: [],
      viewShops: [],
      myShops: [],
      viewShopsGeoJson: {}
    });

    let totalShops;

    this.db.collection("v2.coors").onSnapshot(function(querySnapshot) {
      let allShops = []
      let myShops = [];
      const currentUser = self.state.currentUser

      querySnapshot.forEach((doc) => {
        const { value, shops_total } = doc.data();
        totalShops = shops_total;
        const valueArray = JSON.parse(value)

        allShops.push(...valueArray);
      });


      let features = []

      allShops.forEach((shopInfo) => {
        if (currentUser !== null) {
          if (currentUser.uid === shopInfo.owner) {
            myShops.push(shopInfo);
          }
        }

        features.push({
          type: "Feature",
          properties: shopInfo,
          geometry: {
            type: "Point",
            coordinates: [shopInfo.coors[1], shopInfo.coors[0]]
          }
        })
      });

      let viewShopsGeoJson = {
        type: "FeatureCollection",
        features: features
      }

      // Disable import shops
      // allShops = allShops.filter(x => !x.id.includes("Import_"))

      self.setState({
        totalShops,
        allShops,
        viewShops: self.sortBycoors(allShops),
        myShops,
        viewShopsGeoJson
      });
    });
  }

  getDistinctCategories(products) {
    if (products === undefined) return [];
    return [...new Set(products.filter(x => x.type).map(x => x.type))]
  }

  generateShopCoorsByState() {
    const { allShops } = this.state
    this.generateShopCoors(allShops, false)
  }

  // Dangerous function
  generateShopCoorsByShops() {
    const self = this
    let allShops = []

    this.db.collection("v2.shops").get().then(function(querySnapshot) {
      querySnapshot.forEach((doc) => {
        const shopInfo = {...doc.data()}
        allShops.push(shopInfo);
      });

      if (self.isDevEnv()) {
        console.log({allShops: allShops});
      }

      self.generateShopCoors(allShops)
    });
  }

  generateShopCoorsByShopsOffline() {
    const self = this
    let allShops = []

    sampleShops.forEach((shop) => {
      allShops.push(shop);
    });


    let shopsCoors = []
    allShops.forEach(function(shop) {
      const shopCoors = self.convertToShopCoors(shop)
      shopsCoors.push(shopCoors)
    })
    console.log({shopsCoors: shopsCoors});
  }

  convertToShopCoors(shop) {
    return {
      id: shop.id,
      name: shop.name,
      coors: shop.coors,
      address: shop.address,
      categories: this.getDistinctCategories(shop.products),
      verifyCount: shop.verifyCount,
      city: shop.city,
      owner: shop.owner
    }
  }

  generateShopCoors(added_shops, haveToConvert = true) {
    const self = this;
    const { currentUser } = this.state
    // console.log(ImportedData.length);
    const shops = added_shops.concat(ImportedData)
    const shopsTotal = shops.length;
    const shopsByUser = shopsTotal - 198

    let created_by;
    if (this.isDevEnv()) {
      created_by = "admin"
    } else {
      created_by = currentUser ? currentUser.uid : null
    }

    let shopsCoors = []

    if (haveToConvert) {
      shops.forEach(function(shop) {
        const shopCoors = self.convertToShopCoors(shop)
        shopsCoors.push(shopCoors)
      })
    } else {
      shopsCoors = shops
    }

    const itemsEachDocument = 1000
    let indexCollection = 0
    do {
      const shopCollection = shopsCoors.splice(0, itemsEachDocument)
      // const shopsByRole = this.shopsByRole(shopCollection)
      const collectionData = {
        shops_total: shopsTotal,
        shops_in_collection: shopCollection.length,
        shops_added: added_shops.length,
        shops_imported: ImportedData.length,
        shops_by_user: shopsByUser,
        shops_by_editor: 198,
        created_at: new Date().toString(),
        created_by: created_by,
        value: JSON.stringify(shopCollection),
      }

      this.db.collection("v2.coors").doc(indexCollection.toString()).set(collectionData);

      if (this.isDevEnv()) {
        console.log({collectionData: collectionData});
      }

      indexCollection++;
    }
    while (indexCollection < shopsCoors.length);
  }

  toggleEditingCoors(shopCoorsEditingId = null) {
    this.setState({
      shopCoorsEditingId,
      showModalViewShop: false
    });
  }

  togglePopover(step = 0) {
    const { currentUser, myShops, shopCoorsEditingId } = this.state
    if (currentUser === null || myShops.length > 0 || shopCoorsEditingId !== null) {
      step = 0
    }

    switch (step) {
      case 1:
        this.setState({popoverStepOne: !this.state.popoverStepOne});
        break;
      case 2:
        this.setState({popoverStepTwo: !this.state.popoverStepTwo});
        break;
      case 3:
        this.setState({popoverStepThree: !this.state.popoverStepThree});
        break;
      default:
        this.setState({
          popoverStepOne: false,
          popoverStepTwo: false,
          popoverStepThree: false,
        });
        break;
    }
  }

  updateCoors() {
    const self = this;
    const { allShops, shopCoorsEditingId } = this.state
    const newCoors = [this.state.viewport.latitude, this.state.viewport.longitude]

    this.db.collection("v2.shops").doc(shopCoorsEditingId).update({
      coors: newCoors
    }).then(function() {
      const shop = allShops.find(x => x.id === shopCoorsEditingId)
      const shopIndex = allShops.indexOf(shop)
      self.setState({
        allShops: update(allShops, {[shopIndex]: {
          coors: {$set: newCoors}
        }}),
      })
      self.generateShopCoorsByState()
    });

    this.setState({
      shopCoorsEditingId: null
    });

    this.showToast("Cập nhật thành công!")
  }

  onChangeCity(event) {
    const target = event.target;
    const cityIndex = target.value;
    let viewShops = this.state.allShops

    if (cityIndex !== "") {
      const city = cities[cityIndex];
      const value = city.name;

      viewShops = viewShops.filter(shop => shop.city === value);

      const coors = city.coors.split(",");

      const viewport = {
        longitude: parseFloat(coors[1]),
        latitude: parseFloat(coors[0]),
        zoom: 12,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 3000
      }

      this.handleGeocoderViewportChange(viewport)
    }

    this.setState({
      currentSelectedCity: cityIndex,
      viewShops
    });

    if (this.state.myShops.length === 0 && this.state.headerTabActive === 2) {
      this.setState({
        popoverStepOne: false,
        popoverStepTwo: true,
        popoverStepThree: false
      });
    } else {
      this.togglePopover()
    }
  }

  isAdmin() {
    return this.isDevEnv()
  }

  isDevEnv() {
    return process.env.NODE_ENV === "development"
  }

  onClickMarker = (shop) => {
    const self = this
    // Show shop
    if (shop.id.includes("Import_")) {
      this.getDetailShopOffline(shop)
    } else {
      this.getDetailShopOnline(shop)
    }
    return;
  };

  getDetailShopOffline(shop) {
    const shop_id_arr = shop.id.split("_")
    const shop_imported_name = shop_id_arr.slice(1, -1).join("_")
    const shopIsOpen = ImportedDataArray[shop_imported_name].find(x => x.id === shop.id)

    this.setStateDetailShop(shopIsOpen)
  }

  getDetailShopOnline(shop) {
    const self = this
    this.db.collection("v2.shops").doc(shop.id).get().then(function(doc) {
      let shopIsOpen
      if (doc.exists) {
        shopIsOpen = {...doc.data()}
      }
      self.setStateDetailShop(shopIsOpen)
    });
  }

  setStateDetailShop(shopIsOpen) {
    this.setState({
      showModalViewShop: !this.state.showModalViewShop,
      shopIsOpen
    });
  }

  askForInitLocation() {
    let self = this

    this.setState({ isFindingLocation: true });

    navigator.geolocation.getCurrentPosition(function(position) {
      const viewport = {
        longitude: position.coords.longitude,
        latitude: position.coords.latitude,
        zoom: 14,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 3000
      }

      self.handleGeocoderViewportChange(viewport)

      self.setState({
        viewport: viewport,
        isFindingLocation: false
      })
    }, function(error) {
      // console.log(`ERROR(${error.code}): ${error.message}`);

      self.setState({
        isFindingLocation: false
      })
    }, {enableHighAccuracy: true});

    if (this.state.myShops.length === 0 && this.state.headerTabActive === 2) {
      this.setState({
        popoverStepOne: false,
        popoverStepTwo: true,
        popoverStepThree: false
      });
    } else {
      this.togglePopover()
    }
  }

  isOwner(ownerID) {
    const { currentUser } = this.state
    if (currentUser === null || ownerID === null) return false
    if (currentUser.uid === ownerID) return true

    return false
  }

  isEditor() {
    // if (this.isDevEnv()) return true;

    const currentUser = this.state.currentUser
    if (currentUser === null) return false

    const currentUserMail = currentUser.email

    if (editors.map(x => x.email).includes(currentUserMail)) {
      return true
    }

    return false
  }

  checkIfDuplicateExists(w){
    return new Set(w).size !== w.length
  }

  shopsByRole(shops) {
    const uidEditors = editors.map(x => x.uid)

    let shopsByUser = []
    shops.forEach(function(shop) {
      if (!uidEditors.includes(shop.owner)) {
        shopsByUser.push(shop)
      }
    })

    const shopsByEditor = shops.length - shopsByUser.length
    const shopsByRole = {user: shopsByUser.length, editor: shopsByEditor}

    console.log({shopsByRole: shopsByRole});

    return shopsByRole
  }

  removeShopById(shops, shopId) {
    return shops.filter(x => x.id !== shopId)
  }

  findShopDuplicate() {
    let strArray = this.state.allShops.map(x => x.name)
    let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)

    const duplicateShops = [...new Set(findDuplicates(strArray))]
    console.log({duplicateShops: duplicateShops});
  }

  // componentWillReceiveProps(nextProps) {
  //   console.log(nextProps);
  //   this.setState({viewShopsGeoJson: nextProps.viewShopsGeoJson})
  // }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async currentUser => {
      if (currentUser) {
        this.setState({
          currentUser
        })
      }

      if (process.env.NODE_ENV === "development") {
        this.setupForDevEnv()
      } else {
        this.setShopsStateByCoors()
      }
    });

    if (process.env.NODE_ENV !== "development") {
      firebase.analytics();
    }

    // this.db.collection("v2.shops").doc("Wwfgem31m7TS8Met2NDreEvarTD3-1587651691507").delete().then(function() {
    //   console.log("remove!");
    // })
  }

  handleViewportChange = (viewport) => {
    this.setState({
      viewport: { ...this.state.viewport, ...viewport }
    })
  }

  handleGeocoderViewportChange = (viewport) => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 }

    return this.handleViewportChange({
      ...viewport,
      ...geocoderDefaultOverrides
    })
  }

  editShop(myShopIndex) {
    const myShop = this.state.myShops[myShopIndex]
    if (myShop.owner === this.state.currentUser.uid) {
      this.setState({
        showModalNewShop: !this.state.showModalNewShop
      })
    }
  }

  setHeaderTabActive(headerTabActive) {
    if (headerTabActive === 1){
      this.togglePopover()
    } else if (headerTabActive === 2) {
      this.togglePopover(1)
    }
    this.setState({
      headerTabActive
    })
  }

  showToast(toastMessage) {
    clearInterval(this.state.toastTimer);

    this.setState({
      toastMessage,
      toastIsOpen: true,
      toastTimer: setInterval(() => this.setState({toastIsOpen: false}), 2000)
    })
  }

  onClickCard(coors) {
    this.setState({
      headerTabActive: 1
    })
    const viewport = {
      longitude: coors[1],
      latitude: coors[0],
      zoom: 15,
      transitionInterpolator: new FlyToInterpolator(),
      transitionDuration: 3000
    }
    this.handleGeocoderViewportChange(viewport)
  }

  onChangeFilter(items) {
    let filterValues = items === null ? [] : items.map((item => item.value))
    this.setState({
      filterValues
    });
  }

  shopItemDisplay(categories, owner = "") {
    if (owner !== "") {
      const currentUser = this.state.currentUser
      const notMyShop = currentUser === null || (currentUser !== null && currentUser.uid !== owner)
      if (this.state.headerTabActive !== 1 && notMyShop) {
        return "d-none"
      }
    }

    const { filterValues } = this.state
    if (filterValues.length === 0){
      return "";
    }

    const inncludeCategories = filterValues.filter(item => categories.includes(item));
    return inncludeCategories.length ? "" : "d-none"
  }

  shopFilterCategories(categories, owner = "") {
    if (owner !== "") {
      const currentUser = this.state.currentUser
      const notMyShop = currentUser === null || (currentUser !== null && currentUser.uid !== owner)
      if (this.state.headerTabActive !== 1 && notMyShop) {
        return "d-none"
      }
    }

    const { filterValues } = this.state
    if (filterValues.length === 0){
      return "";
    }

    const inncludeCategories = filterValues.filter(item => categories.includes(item));
    return inncludeCategories.length ? "" : "d-none"
  }

  sortBycoors(shops) {
    var options = { units: 'miles' };
    const currentCoor = [this.state.viewport.latitude, this.state.viewport.longitude]

    let nearShops = []
    shops.forEach(function(shop) {
      Object.defineProperty(shop, "distance", {
        value: turf.distance(currentCoor, shop.coors, options),
        writable: true,
        enumerable: true,
        configurable: true
      });
      if (shop.distance < 1) {
        nearShops.push(shop)
      }
    });

    nearShops.sort(function(a, b) {
      if (a.distance < 0.5) {
        if (a.distance > b.distance) return 1;
        if (a.distance < b.distance) return -1;

        if (a.verifyCount < b.verifyCount) return 1;
        if (a.verifyCount > b.verifyCount) return -1;
      } else {
        if (a.verifyCount < b.verifyCount) return 1;
        if (a.verifyCount > b.verifyCount) return -1;

        if (a.distance > b.distance) return 1;
        if (a.distance < b.distance) return -1;
      }

      return 0;
    });

    return nearShops;
    // return shops
  }

  canVerify(shop) {
    const {currentUser} = this.state;
    if (currentUser == null) return false;

    return currentUser.uid !== shop.owner && !this.wasVerify(shop.verify)
  }

  wasVerify(shopVerify) {
    const currentUserID = this.state.currentUser.uid
    shopVerify = shopVerify === "" ? [] : shopVerify
    return shopVerify.includes(currentUserID)
  }

  verifyShop(shop) {
    const self = this;
    const currentUserID = this.state.currentUser.uid
    let shopVerify = shop.verify === "" ? [] : shop.verify

    if (!this.wasVerify(shopVerify)) {
      shopVerify.push(currentUserID)
      this.db.collection("v2.shops").doc(shop.id).update({
        verify: shopVerify,
        verifyCount: shopVerify.length
      })
      .then(function() {
        // TODO
        // const shop = allShops.find(x => x.id === shopCoorsEditingId)
        // const shopIndex = allShops.indexOf(shop)
        // self.setState({
        //   allShops: update(allShops, {[shopIndex]: {
        //     coors: {$set: newCoors}
        //   }}),
        // })
        // self.generateShopCoorsByState()
      });

      const shopIndex = this.state.shopIsOpenIndex

      shop.verify = shopVerify
      shop.verifyCount = shopVerify.length

      this.setState({
        viewShops: update(this.state.viewShops, {[shopIndex]: {
          verify: {$set: shopVerify},
          verifyCount: {$set: shopVerify.length}
        }}),
        shopIsOpen: shop
      })

      this.showToast("Xác thực thành công!")
    }
  }

  progressClickData() {
    const self = this
    const today = new Date()
    const todayKey = today.getFullYear() + '-' + (today.getMonth() + 1)  + '-' + today.getDate()
    const shop = this.state.shopIsOpen
    const shopId = shop.id

    this.db.collection("v2.clicks").doc("all").get().then(function(doc) {
      let totalClickData = doc.data()
      let clickData = totalClickData["1_clicks"]

      if (clickData[todayKey] === undefined) {
        clickData[todayKey] = {
          "total": 1,
          "value": { shopId: 1 }
        }
      } else {
        let value = clickData[todayKey]["value"]
        if (value[shopId]  === undefined) {
          value[shopId] = 1
        } else {
          value[shopId] = ++value[shopId]
        }

        clickData[todayKey] = {
          "total": ++clickData[todayKey]["total"],
          "value": value
        }
      }

      totalClickData["0_total_clicks"] = ++totalClickData["0_total_clicks"]

      self.db.collection("v2.clicks").doc("all").set(totalClickData);
    })
  }

  togglePhoneNumber(value) {
    const showPhoneNumber = value ? value : !this.state.showPhoneNumber
    if (showPhoneNumber) {
      this.progressClickData()
    }

    this.setState({
      showPhoneNumber
    })
  }

  skipUndefined() {
    const { totalShops, allShops } = this.state
    return totalShops > 0 && (totalShops <= allShops.length)
  }

  render() {
    const {
      viewport, settings, currentUser,
      viewShops, viewShopsGeoJson, myShops, shop, productTypes, productUnits,
      showModalNewShop, activeTabNewShop, showModalViewShop, shopIsOpen, isFindingLocation,
      headerTabActive, currentSelectedCity, shopCoorsEditingId, productTypeOptions,
      toastIsOpen, toastMessage,
      popoverStepOne, popoverStepTwo, popoverStepThree, enableAddShopBtn,
      showPhoneNumber
    } = this.state;

    return (
      <div id="map">
        <aside className={`sidebar sidebar-md d-none d-md-flex`}>
          <div className="sidebar-header bg-white">
            <div className="btn-group-wrap d-flex align-items-center">
              <ButtonGroup className="flex-grow-1">
                <Button color="outline-primary" onClick={() => this.setHeaderTabActive(1)} active={headerTabActive === 1}>Mask Map</Button>
                <Button color="outline-primary" onClick={() => this.setHeaderTabActive(2)} active={headerTabActive === 2}>Shop của tôi</Button>
              </ButtonGroup>
            </div>
            <div className={`mt-3 bg-white`}>
              <div className="d-flex align-items-center" id="popover-select-city">
                <select className="custom-select" value={currentSelectedCity} onChange={(event) => this.onChangeCity(event)}>
                  <option value="">Toàn Quốc</option>
                  { cities.map((city, index) => (<option value={index} key={index}>{city.name}</option>)) }
                </select>
                <a onClick={() => this.askForInitLocation()} className={`icon-gps`}>
                  <GPS rotate={isFindingLocation}/>
                </a>
              </div>
              <Select
                isMulti
                options={productTypeOptions}
                placeholder="Lọc.."
                styles={colourStyles}
                onChange={(items) => this.onChangeFilter(items)}
                className={`mt-2 ${headerTabActive === 1 ? "": "d-none"}`}
              />

              <Popover placement="right" isOpen={popoverStepOne} target="popover-select-city"  className="d-none d-md-block">
                <PopoverHeader>Tìm vị trí Shop của bạn</PopoverHeader>
                <PopoverBody>Bấm vào nút định vị vị trí hoặc chọn tỉnh thành của bạn</PopoverBody>
              </Popover>
            </div>
          </div>
          <div className="sidebar-body">
            <div className={`${headerTabActive === 1 ? "": "d-none"}`}>
            {
              this.skipUndefined() &&
              viewShops.map((shop, index) => {
                return (
                  <div className={`card ${this.shopItemDisplay(shop.categories)}`} key={index}>
                    <div className="card-body">
                      <h5 className="card-title mb-1">{shop.name} <Verify total={shop.verifyCount}/></h5>
                      <p className="card-text mb-2">
                        {shop.address}
                        { shop.coors === "" ? console.log(shop) : "" }
                        <a className="small ml-2" target="_blank" rel="noopener noreferrer" href={`http://www.google.com/maps/place/${shop.coors.join(",")}`}>Google Maps</a>
                      </p>
                      {
                        shop.categories.map((type, index) => {
                          switch (type) {
                            case productTypes[0]:
                              return <Badge color="primary-third" className="mr-2" key={index}>{type}</Badge>
                            case productTypes[1]:
                              return <Badge color="primary-secondary small p-2" className="mr-2" key={index}>{type}</Badge>
                            case productTypes[2]:
                              return <Badge color="primary" className="mr-2" key={index}>{type}</Badge>
                            case productTypes[3]:
                              return <Badge color="primary-fourth" className="mr-2" key={index}>{type}</Badge>
                            default:
                              return <Badge color="secondary" className="mr-2" key={index}>{type}</Badge>
                          }
                        })
                      }
                      <Button color="outline-primary" size="sm" className="d-flex ml-auto" onClick={() => this.onClickCard(shop.coors)}>Xem</Button>
                    </div>
                  </div>
                )
              })
            }
            </div>
            <div className={`${headerTabActive === 2 ? "": "d-none"}`}>
            {
              myShops.map((shop, index) => {
                return (
                  <div className="card" key={index}>
                    <div className="card-body">
                      <h5 className="card-title mb-1">{shop.name} <Verify total={shop.verifyCount}/></h5>
                      <p className="card-text mb-2">
                        {shop.address}
                        <a className="small ml-2" target="_blank" rel="noopener noreferrer" href={`http://www.google.com/maps/place/${shop.coors.join(",")}`}>Google Maps</a>
                      </p>
                      <Button className="mr-2" color="outline-primary" onClick={() => this.toggleModalShopNewShop(shop)}>Sửa thông tin</Button>
                      <Button className="mr-2" color="outline-primary" onClick={() => this.toggleEditingCoors(shop.id)}>Sửa vị trí</Button>
                      <Button color="outline-secondary" onClick={() => this.removeShop(shop)}>Xóa</Button>
                    </div>
                  </div>
                )
              })
            }
            </div>
            <div className={`card ${headerTabActive === 2 && currentUser === null ? "d-flex" : "d-none"}`}>
              <div className="card-body">
                <h5 className="mb-0">Vui lòng đăng nhập để thêm Shop</h5>
              </div>
            </div>
          </div>
          <div className="sidebar-footer p-3 mt-auto bg-white">
            {
              currentUser ?
              <Button color="outline-secondary" block onClick={() => this._signOut()}>Đăng xuất</Button>
              :
              <Button color="primary" block onClick={() => this._signIn()}>Đăng nhập</Button>
            }
          </div>
        </aside>

        <div className="header d-block d-md-none flex-column">
          <Nav tabs className="header-top d-flex">
            <NavItem>
              <NavLink
                onClick={() => this.setHeaderTabActive(1)} active={headerTabActive === 1}
              >Mask Map</NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() => this.setHeaderTabActive(2)} active={headerTabActive === 2}
              >Shop của tôi</NavLink>
            </NavItem>
            <NavItem className="item-hambuger">
              <NavLink
                onClick={() => this.setHeaderTabActive(3)} active={headerTabActive === 3}
              ><i className="gg-menu"></i>
              </NavLink>
            </NavItem>
          </Nav>
          <div className="header-body">
            <aside className={`sidebar sidebar-sm`}>
              <div className="sidebar-header bg-white p-2 border-bottom">
                <div className="d-flex align-items-center mb-2" id="popover-select-city-sm">
                  <select className="custom-select" value={currentSelectedCity} onChange={(event) => this.onChangeCity(event)}>
                    <option value="">Toàn Quốc</option>
                    { cities.map((city, index) => (<option value={index} key={index}>{city.name}</option>)) }
                  </select>
                  <a onClick={() => this.askForInitLocation()} className="icon-gps">
                    <GPS/>
                  </a>
                </div>
                <Select isMulti options={productTypeOptions} placeholder="Lọc.." styles={colourStyles} onChange={(items) => this.onChangeFilter(items)} className={`${headerTabActive === 3 ? "" : "d-none"}`}
                />
                {/* <div className="">
                  {
                    currentUser ?
                    <Button color="outline-secondary" block onClick={() => this._signOut()}>Đăng xuất</Button>
                    :
                    <Button color="primary" block onClick={() => this._signIn()}>Đăng nhập</Button>
                  }
                </div> */}
                <Popover placement="bottom" isOpen={popoverStepOne} target="popover-select-city-sm" className="d-block d-md-none">
                  <PopoverHeader>Tìm vị trí Shop của bạn</PopoverHeader>
                  <PopoverBody>Bấm vào nút định vị Vị trí hoặc chọn tỉnh thành của bạn</PopoverBody>
                </Popover>

              </div>

              <div className={`sidebar-body no-filter ${!currentUser && headerTabActive === 2 ? "d-flex" : "d-none"} flex-column justify-content-center px-3`}>
                <Button color="primary" block onClick={() => this._signIn()}>Đăng nhập để thêm Shop</Button>
              </div>
              <div className={`sidebar-body ${headerTabActive === 3 ? "" : "d-none"}`}>
                <div className={`shop-items`}>
                {
                  this.skipUndefined() &&
                  viewShops.map((shop, index) => {
                    return (
                      <div className={`card`} key={index}>
                        <div className="card-body">
                          <h5 className="card-title mb-1">{shop.name} <Verify total={shop.verifyCount}/></h5>
                          <p className="card-text mb-2">
                            {shop.address}
                            <a className="small ml-2" target="_blank" rel="noopener noreferrer" href={`http://www.google.com/maps/place/${shop.coors.join(",")}`}>Google Maps</a>
                          </p>
                          {
                            shop.categories.map((type, index) => {
                              switch (type) {
                                case productTypes[0]:
                                  return <Badge color="primary-third" className="mr-2" key={index}>{type}</Badge>
                                case productTypes[1]:
                                  return <Badge color="primary-secondary small p-2" className="mr-2" key={index}>{type}</Badge>
                                case productTypes[2]:
                                  return <Badge color="primary" className="mr-2" key={index}>{type}</Badge>
                                case productTypes[3]:
                                  return <Badge color="primary-fourth" className="mr-2" key={index}>{type}</Badge>
                                default:
                                  return <Badge color="secondary" className="mr-2" key={index}>{type}</Badge>
                              }
                            })
                          }
                        <Button color="outline-primary" size="sm" className="d-flex ml-auto" onClick={() => this.onClickCard(shop.coors)}>Xem</Button>
                        </div>
                      </div>
                    )
                  })
                }
                </div>
              </div>
            </aside>
          </div>
        </div>

        <MapGL
          {...viewport}
          {...settings}
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/mapbox/streets-v11"
          onViewportChange={this._onViewportChange}
          onInteractionStateChange={this._onInteractionStateChange}
          mapboxApiAccessToken={this.mapboxToken}
          interactiveLayerIds={['cluster-count', 'unclustered-point']}
          ref={this.mapRef}
          onClick={this._onClick}
        >
          {
            (this.isEditor() || this.isDevEnv()) &&
              <Geocoder
              mapRef={this.mapRef}
              onViewportChange={this.handleGeocoderViewportChange}
              mapboxApiAccessToken={this.mapboxToken}
              reverseGeocode={true}
              />
          }

          {/* {
            viewShops.map((shop, index) => {
              return (<Marker
              key={index}
              latitude={shop.coors[0]} longitude={shop.coors[1]}
              className={this.shopItemDisplay(shop.categories, shop.owner)}
              offsetTop={0}
              offsetLeft={-16}
              >
                <div className="shop-marker" onClick={() => this.onClickMarker(shop, index)}>
                  <span>{shop.name}</span>
                </div>
              </Marker>)
            })
          } */}

        { Object.keys(viewShopsGeoJson).length !== 0 &&
        <Source
          type="geojson"
          data={viewShopsGeoJson}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={30}
          ref={this.mapRef}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer}/>
        </Source>}

          <Marker
            latitude={viewport.latitude}
            longitude={viewport.longitude}
            offsetTop={-16}
            offsetLeft={-16}
            className={`${headerTabActive === 2 && currentUser !== null ? "": "d-none"}`}
          >
            <Pin size={40} />
          </Marker>
          <span id="popover-marker-pin"></span>
          <Popover placement="bottom" isOpen={popoverStepTwo} target="popover-marker-pin" toggle={() => this.togglePopover(2)}>
            <PopoverHeader>Chọn vị trí Shop</PopoverHeader>
            <PopoverBody>Phóng to bản đồ và kéo thả để chọn chính xác vị trí Shop của bạn.</PopoverBody>
          </Popover>

          <div className="center-bottom center-top-sm">
              <div className={shopCoorsEditingId === null? "d-none" : ""}>
                <Button size="lg" className={`mr-3 ${headerTabActive === 2 ? "": "d-none"}`} color="secondary" onClick={() => this.toggleEditingCoors()}>Hủy</Button>
                <Button size="lg" className={`${headerTabActive === 2 ? "": "d-none"}`} color="primary" onClick={() => this.updateCoors()}>Cập nhật vị trí</Button>
              </div>
              <Button id="popover-add-shop" size="lg" className={`${headerTabActive === 2 && currentUser !== null && shopCoorsEditingId === null ? "": "d-none"}`} color="primary" disabled={!enableAddShopBtn} onClick={() => this.toggleModalShopNewShop()}>Thêm Shop</Button>
              <UncontrolledPopover placement="bottom" isOpen={popoverStepThree} target="popover-add-shop" toggle={() => this.togglePopover(3)}>
                <PopoverHeader>Thêm thông tin Shop</PopoverHeader>
                <PopoverBody>Bấm vào đây để thêm thông tin Shop & thông tin sản phẩm</PopoverBody>
              </UncontrolledPopover>
          </div>

          <small className="right-bottom">
            <a href="https://flamedia.vn/mask-map/huong-dan-dang-ky-dia-diem-mask-map-viet-nam/" target="_blank">Hướng dẫn đăng ký</a>
            <span>Đảo và quần đảo Việt Nam: Đang cập nhật</span>
            </small>

          <Modal className="modal-new-shop modal-dialog-centered modal-dialog-scrollable" isOpen={showModalNewShop} toggle={() => this.toggleModalShopNewShop()}>
            <ModalHeader toggle={() => this.toggleModalShopNewShop()}>Thông tin cửa hàng</ModalHeader>
            <ModalBody>
              <form>
                <Nav tabs className="mb-3">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTabNewShop === 'new-shop-info' })}
                      onClick={() => { this._toggleTabNewShop('new-shop-info'); }}
                    >
                      Thông tin shop
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTabNewShop === 'new-products-info' })}
                      onClick={() => { this._toggleTabNewShop('new-products-info'); }}
                    >
                      Sản phẩm
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTabNewShop}>
                  <TabPane tabId="new-shop-info">
                    <div className="form-group row">
                      <div className="col">
                        <input type="text" className="form-control" name="name" placeholder="Tên shop" value={shop.name} onChange={this._handleInputChange}/>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col">
                        <select className="custom-select" required name="city" value={shop.city} onChange={this._handleInputChange}>
                          <option value="">Chọn Tỉnh/Thành phố</option>
                          {
                            cities.map((city, index) =>
                              (
                                <option value={city.name} key={index}>{city.name}</option>
                              )
                            )
                          }
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col">
                        <input type="text" className="form-control" name="address" placeholder="Địa chỉ chi tiết" value={shop.address} onChange={this._handleInputChange}/>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col">
                        <input type="number" className="form-control" name="phone" placeholder="Số điện thoại" value={shop.phone} onChange={this._handleInputChange}/>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col">
                        <textarea className="form-control" name="description" placeholder="Mô tả" value={shop.description} onChange={this._handleInputChange}></textarea>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" name="certification" id="has-certification" checked={shop.certification} onChange={this._handleInputChange}/>
                          <label className="custom-control-label" htmlFor="has-certification">Sản phẩm có Giấy Chứng Nhận</label>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="new-products-info" id="new-products-info">
                    { shop.products.map((item, index) => (
                      <div className="form-group row" key={index}>
                        <div className="col-auto custom-file-wrapper pr-0 flex items-center">
                          <div className={`custom-file h-100 ${this.state.shop.products[index].image ? "has-image" : ""}`}>
                            {
                              this.state.shop.products[index].image &&
                              <img src={this.state.shop.products[index].image}/>
                            }
                            <input type="file" accept="image/*" className="custom-file-input h-100" name="image" onChange={event => this._handleProductInputImageChange(event, index)}/>
                            <label className="col-form-label">Tải lên</label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <div className="col-7 pr-0">
                              <input type="text" className="form-control mb-2" placeholder="Tên sản phẩm" name="name" value={shop.products[index].name} onChange={event => this._handleProductInputChange(event, index)}/>
                            </div>
                            <div className="col-4 pr-0">
                              <input type="number" className="form-control col" placeholder="Số lượng" name="quantity" value={shop.products[index].quantity} onChange={event => this._handleProductInputChange(event, index)}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-7 pr-0">
                              <select className="custom-select" name="type" onChange={event => this._handleProductInputChange(event, index)} defaultValue={shop.products[index].type}>
                                <option>Loại...</option>
                                { productTypes.map((name, index) =>  <option key={index} value={name}>{name}</option> )}
                              </select>
                            </div>
                            <div className="col-4 pr-0">
                              <select className="custom-select" name="unit" onChange={event => this._handleProductInputChange(event, index)} defaultValue={shop.products[index].unit}>
                                <option>Đơn vị...</option>
                                { productUnits.map((name, index) =>  <option key={index} value={name}>{name}</option> )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </TabPane>
                </TabContent>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" block onClick={(event) => this.onSubmitForm(event)}>{ shop.id ? "Cập nhật" : "Thêm"}</Button>
            </ModalFooter>
          </Modal>

          { shopIsOpen &&
          <Modal className="modal-view-shop modal-dialog-centered" isOpen={showModalViewShop} toggle={() => this.toggleModalShopViewShop()}>
            <ModalHeader toggle={() => this.toggleModalShopViewShop()}>Thông tin cửa hàng</ModalHeader>
            <ModalBody>
              <h5 className="mb-0">{ shopIsOpen.name } <Verify total={shopIsOpen.verifyCount}/></h5>
              {
                shopIsOpen.certification &&
                <p className="small font-italic">Sản phẩm có Giấy Chứng Nhận</p>
              }
              <p>
                { shopIsOpen.address }
                <a className="small ml-2" target="_blank" rel="noopener noreferrer" href={`http://www.google.com/maps/place/${shopIsOpen.coors.join(",")}`}>Mở Google Maps</a>
              </p>
              {
                shopIsOpen.phone &&
                <div>
                  <p className="mb-1">
                    {
                      showPhoneNumber ?
                      <span>Số Điện Thoại: <a href={`tel:${shopIsOpen.phone}`}>{ shopIsOpen.phone }</a></span>
                      :
                      <Button color="primary" size="sm" onClick={() => this.togglePhoneNumber(true)}>Lấy Số Điện Thoại</Button>
                    }
                  </p>
                  <div className="alert alert-secondary small p-2">
                    Lưu ý: Phòng trường hợp hết sản phẩm. Hãy gọi cho chủ Shop trước khi đến hoặc đặt hàng.
                  </div>
                </div>
              }
              <p className="mb-4">{ shopIsOpen.description }</p>
              { shopIsOpen.products.map((item, index) => (
                  item.name && <ProductItem product={item} key={index}/>
                )
              )}
            </ModalBody>
            <ModalFooter>
              <Button className="mr-auto" color="outline-success" disabled={!this.canVerify(shopIsOpen)} onClick={() => this.verifyShop(shopIsOpen)}>Xác thực Shop</Button>
              <Button color="outline-primary" target="_blank" rel="noopener noreferrer" href={`http://www.google.com/maps/place/${shopIsOpen.coors.join(",")}`}>Mở Google Maps</Button>

              <Button color="primary" onClick={() =>  this.progressClickData()} href={`tel:${shopIsOpen.phone}`} disabled={shopIsOpen.phone ? false : true}>Gọi</Button>
            </ModalFooter>
            {
              (
                this.isDevEnv() ||
                (headerTabActive === 2 && this.isOwner(shopIsOpen.owner))
              ) &&
                <ModalFooter>
                <Button size="sm" className="mr-auto" color="outline-secondary" onClick={() => this.removeShop(shopIsOpen)}>Xóa</Button>
                <Button size="sm" className="mr-2" color="outline-primary" onClick={() => this.toggleEditingCoors(shopIsOpen.id)}>Sửa vị trí</Button>
                <Button size="sm" className="mr-2" color="outline-primary" onClick={() => this.toggleModalShopNewShop(shopIsOpen)}>Sửa thông tin</Button>
              </ModalFooter>
            }
          </Modal>
          }

          <Toast isOpen={toastIsOpen} className="center-top">
            <ToastBody>
            <i className="gg-check-o mr-2"></i>
            {toastMessage}
            </ToastBody>
          </Toast>
        </MapGL>
      </div>
    );
  }
}
