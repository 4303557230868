import * as React from 'react';
import {PureComponent} from 'react';
import { Badge } from "reactstrap";

export default class ProductItem extends PureComponent {
  render() {
    const {product} = this.props;

    let type;
    const productTypes = ["Khẩu trang", "Gel rửa tay khô", "Kính bảo hộ", "Áo mưa kháng khuẩn", "Khác"];

    switch (product.type) {
      case productTypes[0]:
        type = <Badge color="primary-third" className="mr-2">{product.type}</Badge>
        break;
      case productTypes[1]:
        type = <Badge color="primary-secondary" className="mr-2">{product.type}</Badge>
        break;
      case productTypes[2]:
        type = <Badge color="primary" className="mr-2">{product.type}</Badge>
        break;
      case productTypes[3]:
        type = <Badge color="primary-fourth" className="mr-2">{product.type}</Badge>
        break;
      default:
        type = <Badge color="secondary" className="mr-2">{product.type}</Badge>
        break;
    }

    return (
      <div className="form-group row">
        {
          product.image &&
          <div className="col-auto custom-file-wrapper pr-0 flex items-center">
            <div className={`custom-file h-100 border-0 ${product.image ? "has-image" : ""}`}>
              <img src={product.image}/>
            </div>
          </div>
        }
        <div className="col">
          <h5>{ product.name }</h5>
          <p className="mb-0">
          {
            product.quantity &&
            <span className="mr-2">Số lượng: { product.quantity }</span>
          }
          {
            product.unit &&
            <span>Đơn vị: { product.unit }</span>
          }
          </p>
          {type}
        </div>
      </div>
    );
  }
}
