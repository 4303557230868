import * as React from 'react';
import {PureComponent} from 'react';

export default class GPS extends PureComponent {
  render() {
    const { rotate } = this.props

    return (
      <svg className={`${rotate ? "rotating" : ""}`} viewBox="0 0 512.001 512"><g><path d="m255.863281 168.699219c-48.046875 0-87.140625 39.09375-87.140625 87.144531 0 48.046875 39.09375 87.140625 87.140625 87.140625 48.050781 0 87.144531-39.09375 87.144531-87.140625 0-48.050781-39.09375-87.144531-87.144531-87.144531zm0 0" data-original="#000000" className="active-path" data-old_color="#000000" fill="#212529"/><path d="m497.003906 240.84375h-55.054687c-7.269531-91.003906-80.082031-163.820312-171.089844-171.085938v-54.761718c0-8.28125-6.714844-14.996094-14.996094-14.996094s-14.996093 6.714844-14.996093 14.996094v54.761718c-91.007813 7.265626-163.820313 80.082032-171.089844 171.085938h-54.78125c-8.28125 0-14.996094 6.714844-14.996094 15 0 8.28125 6.714844 14.996094 14.996094 14.996094h54.78125c7.269531 91.003906 80.082031 163.820312 171.089844 171.089844v54.757812c0 8.28125 6.714843 14.996094 14.996093 14.996094 8.285157 0 14.996094-6.714844 14.996094-14.996094v-54.757812c91.007813-7.269532 163.824219-80.085938 171.089844-171.089844h55.054687c8.28125 0 14.996094-6.714844 14.996094-14.996094 0-8.285156-6.714844-15-14.996094-15zm-241.140625 171.695312c-86.402343 0-156.695312-70.296874-156.695312-156.695312 0-86.402344 70.292969-156.699219 156.695312-156.699219 86.402344 0 156.695313 70.296875 156.695313 156.699219 0 86.398438-70.292969 156.695312-156.695313 156.695312zm0 0" data-original="#000000" className="active-path" data-old_color="#000000" fill="#212529"/></g> </svg>
    );
  }
}