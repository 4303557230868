import * as React from 'react';
import {PureComponent} from 'react';

export default class Verify extends PureComponent {
  render() {
    const {total} = this.props;
    const verify = total >= 5 ? (
      <span className="icon-verify">
      <svg width="100%" height="100%" viewBox="0 0 24 24" fill="#0F9D58" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="m0 0h24v24h-24zh24v24h-24z" fill="none"></path>
        <path d="M11.14 16L7.3 12.16l1.41-1.42 2.43 2.42L15.3 9l1.42 1.41L11.14 16zM12 4.24l6 3v4.1c0 3.9-2.55 7.5-6 8.59-3.45-1.09-6-4.7-6-8.59v-4.1l6-3M12 2L4 6v5.33c0 4.93 3.41 9.55 8 10.67 4.59-1.12 8-5.73 8-10.67V6l-8-4z"></path>
      </svg>
    </span>
    ) : "";

    return (
      <>
      {verify}
      </>
    );
  }
}
