import firebase from "firebase";

const config = {
  apiKey: "AIzaSyBJERCkSaflIhsd6uxgJT8uAou8B0GW5Ec",
  authDomain: "flamedia-maskmap.firebaseapp.com",
  databaseURL: "https://flamedia-maskmap.firebaseio.com",
  projectId: "flamedia-maskmap",
  storageBucket: "flamedia-maskmap.appspot.com",
  messagingSenderId: "583729461660",
  appId: "1:583729461660:web:b1866281e208dcc1efb059",
  measurementId: "G-C5GLGJF89Q"
};

firebase.initializeApp(config);

export default firebase;
